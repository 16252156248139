import React, { useEffect, useState } from "react";
import $ from "jquery";

import { useNavigate, useParams } from "react-router-dom";
// import Aos from "aos";
import FetchData from "../../components/FetchDataComponent";
import DatePicker from "react-datepicker";
import Loader from "../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
// import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSelectedSlot,
  updateDate,
  updateSelectedPeople,
  updateTotalAmount,
  resetState
} from "../../Redux/reducer";
import GoldenTheater1 from '../../assets/storage/theaters/dd0acd5a-a326-4989-a0ea-d6f7cc3fb5fc1703344234.jpg';
import silver from "../../assets/img/theatres/nagole/silver.png"
import platinum from "../../assets/img/theatres/yusufguda/platinum (1) (1).jpg"
import diamond from "../../assets/img/theatres/yusufguda/IMG_9491 (1).jpg"

const GoldenTheater = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {  name } = useParams();
  const date = useSelector((state) => state.item.selectedDate);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedPeople, setSelectedPeople] = useState(1);
  const [theaterDetails,setTheaterDetails]=useState()
  const fetchSlot = `${process.env.REACT_APP_BASE_URL}api/v1/theater/getTheaterDetails/${selectedDate}/${name}`;
  const { loading, data, error } = FetchData({ link: fetchSlot });
// console.log(itemList);

const itemList = useSelector((state) => state.item.itemList); // Get Redux data

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("slotselected", "false");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  
  useEffect(() => {
    
    if(data){
      setTheaterDetails(data.data);
      setTotalAmount(data.data.theater.price)
    }
  }, [data]);



  useEffect(() => {

    if (itemList.length > 1) {
      localStorage.clear(); // Clear localStorage
      dispatch(resetState()); // Reset Redux state
    }
    const isSetItemTrue = localStorage.getItem("slotselected") === "true";
    if (!isSetItemTrue) {
      navigate("/locations");
    }
  }, []);
  const handleDateChange = (date) => {
    setSelectedDate(date instanceof Date ? date : new Date(date));
  };

  const handelSelect = (event) => {
    setSelectedValue(event.target.value);
  };



  if (loading) {
    return <Loader />;
  }

  if (error || !theaterDetails) {
    return <div>Error: {error}</div>;  
  }

  const originalPrice = theaterDetails.theater.price;


  const handlePeopleSelect = (event) => {
    const numberOfPeople = parseInt(event.target.value);
  
    if (!isNaN(numberOfPeople)) {
      setSelectedPeople(numberOfPeople);
      let newAmount = 0;
  
      const location = theaterDetails.theater.location;
      const theaterName = theaterDetails.theater.name;
      let additionalCostPerPerson = 0;
      let extraPersonThreshold = 0; 
  
      if (location === "Nagole") {
        if (theaterName === "silver") {
          additionalCostPerPerson = 199;
          extraPersonThreshold = 4;
        } else if (theaterName === "gold") {
          additionalCostPerPerson = 199;
          extraPersonThreshold = 5;
        }
      } else if (location === "Yusufguda") {
        if (theaterName === "Platinum") {
          additionalCostPerPerson = 249;
          extraPersonThreshold = 5;
        } else if (theaterName === "Diamond") {
          additionalCostPerPerson = 249;
          extraPersonThreshold = 6;
        }
      } else {
        additionalCostPerPerson = 249; 
        extraPersonThreshold = 5; 
      }
      
  
      if (numberOfPeople > extraPersonThreshold) {
        newAmount = originalPrice + additionalCostPerPerson * (numberOfPeople - extraPersonThreshold);
      } else {
        newAmount = originalPrice;
      }
  
      setTotalAmount(newAmount);
      console.log(newAmount)
    } else {
      setSelectedPeople(0);
      setTotalAmount(0);
    }
  };
  
  


  const NumberOfPeople= theaterDetails.theater.capacity;
  
  const slotsData = theaterDetails.slots
    .filter((slot) => slot.theater === `${name}` )
    .map((slot) => {
      const startTime = new Date(slot.startTime);
      const endTime = new Date(slot.endTime);
      const now = new Date();
  
      const startTimeFormatted = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const endTimeFormatted = endTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const isDisabled = now > endTime || slot.bookingStatus;
      return {
        id: slot._id,
        value: `${startTimeFormatted}-${endTimeFormatted}`,
        label: `${startTimeFormatted} to ${endTimeFormatted}`,
        bookingStatus: isDisabled,
      };
    });

    



  const handelNavigate = async () => {
    const date =
      selectedDate instanceof Date ? selectedDate : new Date(selectedDate);

    const selectedSlot = slotsData.find((slot) => slot.value === selectedValue);
    if (selectedDate && selectedSlot && selectedPeople) {
      dispatch(updateDate(date.toISOString()));
      dispatch(updateSelectedSlot(selectedSlot));
      dispatch(updateSelectedPeople(selectedPeople));
      dispatch(updateTotalAmount(totalAmount));
      navigate("/Decoration");
    } else {
      alert("Selected slot not found in slotsData.");
    }
  };

  // Define a function to get the appropriate image based on the theater name
const getTheaterImage = (theaterName) => {
  switch (theaterName) {
    case "Diamond":
      return diamond;
    case "Platinum":
      return platinum;
    case "silver":
      return silver;
    case "GoldenTheater1":
      return GoldenTheater1;
    default:
      return GoldenTheater1; // Fallback image
  }
};

// Inside your component
const getPersonInfo = (location, theaterName) => {
  if (location === "Nagole") {
    if (theaterName === "silver") {
      return {
        maxPersons: 4,
        extraPersonCharge: 199,
      };
    } else if (theaterName === "gold") {
      return {
        maxPersons: 5,
        extraPersonCharge: 199,
      };
    }
  } else if (location === "Yusufguda") {
    if (theaterName === "Platinum") {
      return {
        maxPersons: 5,
        extraPersonCharge: 249,
      };
    } else if (theaterName === "Diamond") {
      return {
        maxPersons: 6,
        extraPersonCharge: 249,
      };
    }
  }
  // Default case
  return {
    maxPersons: 5,  // Default number of persons
    extraPersonCharge: 249, // Default charge
  };
};

const { maxPersons, extraPersonCharge } = getPersonInfo(theaterDetails.theater.location, theaterDetails.theater.name);



  return (
    <>
      <section className="content-section padding">
        <div className="container">
          <div className="row content-wrap">
            <div className="col-lg-6 sm-padding">
              <div
                className="content-info "
                // data-aos="fade-right"
                // data-aos-delay="300"
              >
                <span>Explore The Features</span>
                <h2>{theaterDetails.theater.title}</h2>
                <p className="text-white">
                {theaterDetails.theater.description}
                </p>
                
              </div>
            </div>
            <div
              className="col-lg-6 sm-padding "
              // data-aos="fade-left"
              // data-wow-delay="300"
            >
                <img
    className="box-shadow"
    src={getTheaterImage(theaterDetails.theater.name)}
    alt={theaterDetails.theater.name}
  />
            </div>
            <div className="col-md-6 sm-padding">
              <div
                id="project-single-carousel"
                className="project-single-carousel box-shadow owl-carousel"
              >
                <div className="single-carousel">
                  <img
                    src="../storage/theaters/dd0acd5a-a326-4989-a0ea-d6f7cc3fb5fc.jpg"
                    alt="Offering the most complete integrated package!"
                    title="Offering the most complete integrated package!"
                  />
                </div>
                <div className="single-carousel">
                  <img
                    src="../storage/theaters/image_50397185.jpg"
                    alt="Offering the most complete integrated package!"
                    title="Offering the most complete integrated package!"
                  />
                </div>
                <div className="single-carousel">
                  <img
                    src="../storage/theaters/image_50394881.jpg"
                    alt="Offering the most complete integrated package!"
                    title="Offering the most complete integrated package!"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block d-sm-none">
        <div className="container-fluid">
          <div
            className="rate-item box-shadow text-center "
            // data-aos="fade-up"
            // data-aos-delay="100"
            style={{
              visibility: "visible",
              animationDelay: "100ms",
              animationName: "fadeInUp",
            }}
          >
            <h1 className="priceHtml">₹{totalAmount}/-</h1>
            <p style={{ color: "#000" }}>inclusive of all taxes</p>
          </div>
        </div>
      </section>
      <section className="padding-20">
        <div className="container">
          <form
            className="form-horizontal"
            method="POST"
            action="https://www.tapesntales.com/carts/store/1"
            id="storecart"
          >
            <input
              type="hidden"
              name="_token"
              defaultValue="Qbak6PCDkyyU6W9STg9JhkwDwWC0KJrharnEoVWV"
            />
            <input
              type="hidden"
              name="theater_id"
              id="theater_id"
              defaultValue={1}
            />
            <input type="hidden" name="checkslotstatus" id="checkslotstatus" />
            <input type="hidden" name="amount" id="amount" />
            <div className="row">
              <div className="col-md-6">
                <div className="wrapper box-shadow" style={{ marginTop: 0 }}>
                  <fieldset>
                    <legend>
                      <center>
                        <h2 style={{ color: "#000" }}>
                          <b>Select your Slot</b>
                        </h2>
                      </center>
                    </legend>
                    <br />
                    <div className="form-group">
                      <label className="col-md-12 control-label">
                        Select Date
                      </label>
                      <div className="col-md-12 inputGroupContainer">
                        <div className="input-group date">
                          <DatePicker
                            className="form-control datepicker"
                            selected={selectedDate}
                            onChange={handleDateChange}
                            minDate={new Date()}
                            dateFormat="MMMM d, yyyy"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-12 control-label">Slots</label>
                      <div className="col-md-12 selectContainer">
                        <div className="input-group">
                          <select
                            className="form-control selectpicker"
                            name="slot_id"
                            id="slot_id"
                            value={selectedValue}
                            onChange={handelSelect}
                            required
                          >
                            <option value="">Select a slot</option>
                            {slotsData.map((slot) => (
                              
                              <option
                                key={`${slot.id}-${slot.startTime}-${slot.endTime}`}
                                value={slot.value}
                                disabled={slot.bookingStatus}
                                style={{backgroundColor : slot.bookingStatus ? 'gray':'white', color: slot.bookingStatus ?'white' : 'black'}}
                              >
                                {slot.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="col-md-6  ">
                <div
                  className="rate-item box-shadow text-center wow fadeInUp d-none d-sm-block"
                  data-wow-delay="100ms; margin-top:10px;"
                  style={{
                    visibility: "visible",
                    animationDelay: "100ms",
                    animationName: "fadeInUp",
                  }}
                >
                  <h1 className="priceHtml">₹{totalAmount}/-</h1>
                  <p style={{ color: "#000" }}>inclusive of all taxes</p>
                </div>
                <div className="wrapper  box-shadow" style={{ marginTop: 10 }}>
                  <fieldset>
                    <div className="form-group">
                      <label className="control-label">No of Persons</label>
                      <div className=" selectContainer">
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="no_of_persons"
                            id="no_of_persons"
                            value={selectedPeople}
                            onChange={handlePeopleSelect}
                            required
                          >
                            <option value="" disabled>
                              Select No. of Persons
                            </option>
                            {[...Array(Number(NumberOfPeople) ).keys()].map((index) => (
                              <option key={index + 1} value={index + 1}>
                                {index + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="input-group" style={{ paddingTop: 20 }}>
                          <button
                            className="default-btn"
                            onClick={handelNavigate}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </form>
          <p className="text-white">
            IMPORTANT: Please choose the approximate number of individuals. The
            ultimate invoice will be calculated based on the actual number of
            attendees.
          </p>
          <p className="text-white">
  We offer a dedicated 2.5 hours time slot only for groups of {maxPersons} individuals.
</p>
<p className="text-white">
  Note: For each additional person exceeding the limit, there will be a charge of ₹{extraPersonCharge}/-. Normal seating will be provided.
</p>

        </div>
      </section>
      {/* Large modal */}
      {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}
      <div
        id="myLargeModal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <p className="text-dark">
                    You've opted for a time slot lasting 1.5 hours, and the
                    charges will reflect this choice. If this works for you,
                    please continue!
                  </p>
                  <p className="text-center">
                    <button
                      className="tn btn-outline-primary"
                      onClick={() => $("#myLargeModal").modal("hide")}
                    >
                      Okay
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoldenTheater;
